
var clickCounter = 0;

$(function () {

    function loadMoreArticles () {

        $moreArticles = $('.js_loadMoreArticles');

        if( !$moreArticles.length ) { return; }

        $moreArticles.one('click', function( event ){

            event.preventDefault();

            var $this        = $(this);
            var $icon        = $this.find('i');
            var url          = $this.data('more-articles');
            var categorySlug = document.location.pathname;

            clickCounter++;

            //slanje evenata nakon ucitavanja dodatnih clanaka
            if( categorySlug === '/' || categorySlug === '' ) {

                categorySlug = 'Naslovnica';

            } else {

                categorySlug = categorySlug.split('/')[1];

            }

            var $removeBtn = $('.js_removeAfterLoad');
            $this.addClass('is_loading');

            $.ajax({

                type: "GET",
                url: url,
                dataType: "html",
                success: function(data) {

                    $this.addClass('is_loading');

                    setTimeout(function(){
                        $icon.removeClass('is_loading');


                        var $data = $(data);

                        $('.js_loadExtraFeed').append($data).removeClass('hidden');

                        $removeBtn.remove();

                        picturePolyfill.parse();

                        loadMoreArticles();

                    }, 1500);

                }
            });
        });
    }

    loadMoreArticles ();

});
