$(function () {


    // elementi modal elementa (za animaciju)
    var search          = $('.js_search'),
        mainNav         = $('.js_mainNav');

    // elementi za animaciju
    var wWidth     = jQuery(window).width(),
        wHeight    = jQuery(window).height();

    var pagePosition = 0;

    var no_transition = $('body').hasClass('no-csstransitions');

    var $toggMenu           = $('.js_toggleMenu');
    var $showNotif          = $('.js_showNotif');
    var $closeNav           = $('.js_closeNavigation');
    var $toggSubmenu        = $('.js_subnavToggle');
    var $closeSubmenu       = $('.js_closeSubmenu');
    var $sticky             = $('.js_sticky');
    var $ntfs               = $('.js_ntfs');
    var $ntfsClose          = $('.js_ntfsClose');
    var $ntfsMarkSeen       = $('.js_markAsSeen');
    var $toggSearch         = $('.js_toggleSearch');
    var $toggObavijesti     = $('.js_openObavijesti');
    var $showPartnerSites   = $('.js_showPartnerSites');


    function checkMobileNav () {}



    var showMenu = function ( $obj, e ) {

        e.preventDefault();


        $('body').toggleClass('has_navigation');

        console.log('statement');

        /*if( $('body').hasClass('has_navigation') ) {

            pagePosition = $('html, body').scrollTop();

            checkMobileNav();

        } else {


            $('html, body').scrollTop( pagePosition );
        }*/

        if( !$('body').hasClass('has_navigation') ) {

            $('body').removeClass('has_subnavigation');
            $toggSubmenu.siblings('.sub_nav').removeClass('is_opened');
        }
    };




    var showSubmenu = function ( $obj, e ) {

        e.preventDefault();

        $('.js_subnavToggle').siblings('.sub_nav').removeClass('is_opened');
        $obj.siblings('.sub_nav').addClass('is_opened');

        if( $obj.hasClass('is_opened') ) {

            $('body').removeClass('has_subnavigation');

        } else {

            $('body').addClass('has_subnavigation');

        }
    };


    var closeSubmenu = function ( $obj, e ) {

        e.preventDefault();

        $('.js_subnavToggle').siblings('.sub_nav').removeClass('is_opened');
        $('body').removeClass('has_subnavigation');

    }


    var showPartnerSites = function ( $obj, e ) {

        e.preventDefault();

        $obj.siblings('.footer_nav').addClass('is_opened');
        $obj.remove();

    }



    var showNotif = function ( $obj, e ) {

        e.preventDefault();

        $('body').addClass('has_notif');
        $ntfs.addClass('is_opened');
    };



    var hideNotif = function () {

        $('body').removeClass('has_notif');
        $ntfs.removeClass('is_opened');
    };





    var markAsSeen = function ( $obj, e ) {

        e.preventDefault();

        $.ajax({

            type: "POST",
            url: $obj.data('api'),

            success: function(data) {

                $('.js_showNotif')
                    .find('.toggle__count')
                    .fadeOut( function(){
                        $(this).remove();
                    } );

                $('.ntfs .ntfs__link').addClass('ntfs__link--seen');

            },

            statusCode: {

                201: function() {
                    // console.log('Uspješno');
                },

                403: function() {
                    // console.log('Korisnik nije logiran');
                },

                408: function() {
                    // console.log('Problem sa serverom - Pokušajte kasnije');
                },

                500: function() {
                    // console.log('Problem sa serverom - Pokušajte kasnije');
                },

                502: function() {
                    // console.log('Problem sa serverom - Pokušajte kasnije');
                }
            }
        });
    };



    /*
    var showExtraNav = function ( $obj, e ) {

        e.preventDefault();

    //  console.log('js_toggleExtraNav', $obj);

        $obj.toggleClass('is_opened')
            .siblings('.articles_list')
            .toggleClass('is_opened');
    };
    */




    // open/close search bar
    var showSearch = function ( $obj, e ) {

        e.preventDefault();

        if( search.hasClass('search--open') ) {

            search.removeClass('search--open');
            search.addClass('search--close');

            mainNav.removeClass('search--open');
            mainNav.addClass('search--close');

        } else {


            search.removeClass('search--close');
            search.addClass('search--open');

            mainNav.removeClass('search--close');
            mainNav.addClass('search--open');

            search.find('.search__txt').focus();

       }
    };


    var showInfo = function ( $obj, e ) {

        e.preventDefault();

        var styModal = $obj.styModal({

            onBeforeClose: function() {
                $('.js_openObavijesti .notiff__count').remove();
            }
        });
    };




    var closeNavigation = function () {

        $('body').removeClass('has_navigation has_subnavigation');
        $('.js_subnavToggle').removeClass('is_opened');

        $('html, body').scrollTop( pagePosition );

        console.log(pagePosition);
    };





    function setTopSubnavigation () {

        var $topNav         = $('.top_navigation');
        var $subnavWrap     = $('.js_subnavWrap');

        if( !$subnavWrap.length ) { return; }

        var $activeNavItem  = $topNav.find('.main_nav__link.is_active');
        var $subnav         = $activeNavItem.siblings('.sub_nav');

        var navSection = $activeNavItem.attr('class');

        if( navSection ) {

            navSection = navSection
                .split('main_nav__link').join('')
                .split('is_active').join('')
                .split('active_').join('');
        }

        $subnav
            .find('.main_nav__span')
            .addClass('bd--' + navSection.trim());

        if( $subnav.find('.is_active').length ) {

            $subnavWrap.addClass('has_active');
        }

        $subnavWrap
            .html($subnav)
            .addClass('is_visible');
    }




    function setSubnavigation () {

        var $topNav = $('.top_navigation');
    }




    $(document).mouseup(function (e){

        var $container = $('.js_sideNav');

                                                    // if the target of the click isn't the container && nor a descendant of the container
        // if ($('body').hasClass('has_navigation') && !$container.is(e.target) && $container.has(e.target).length === 0) {
//
        //     closeNavigation();
        // }


        var $container2 = $('.js_ntfs');
                                                  // if the target of the click isn't the container && nor a descendant of the container
        if ($container2.hasClass('is_opened') && !$container2.is(e.target) && $container2.has(e.target).length === 0) {

            hideNotif();
        }

    });


    // Eventi za navigaciju
    $toggMenu.on            ('click', function(e){ showMenu         ( $(this), e ); });
    $showNotif.on           ('click', function(e){ showNotif        ( $(this), e ); });
    $toggSubmenu.on         ('click', function(e){ showSubmenu      ( $(this), e ); });
    $closeSubmenu.on        ('click', function(e){ closeSubmenu     ( $(this), e ); });
    $toggSearch.on          ('click', function(e){ showSearch       ( $(this), e ); });
    $toggObavijesti.on      ('click', function(e){ showInfo         ( $(this), e ); });
    $ntfsMarkSeen.on        ('click', function(e){ markAsSeen       ( $(this), e ); });
    $showPartnerSites.on    ('click', function(e){ showPartnerSites ( $(this), e ); });

    $closeNav.on        ('click', function(e){ e.preventDefault(); closeNavigation  ( $(this), e ); });
    $ntfsClose.on       ('click', function(e){ e.preventDefault(); hideNotif        ( $(this), e ); });

    // setTopSubnavigation();
    setSubnavigation();
});