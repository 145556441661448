$(function () {

    $("body").on('click', 'a', function(e) {

        var $clicked    = $(this);

        var _eventCategory,
            _eventAction,
            _eventLabel;

        _eventCategory  = $clicked.data('eventcategory');
        _eventAction    = $clicked.data('eventaction');
        _eventLabel     = $clicked.data('eventlabel');


    //  console.log(_eventCategory, _eventAction, _eventLabel);

        if (_eventCategory !== undefined) {

            dataLayer.push({
                'event' : 'GAEvent',
                'eventCategory' : _eventCategory,
                'eventAction' : _eventAction,
                'eventLabel' : _eventLabel,
                'eventValue' : undefined
            });
        }

        var unique = $clicked.data('event-unique');
        if (unique === undefined) {

            var rootEvent = $clicked.closest("[data-event-box]");
            var debAttr = rootEvent.data('event-box');

            if (debAttr !== undefined) {

                var title = $clicked.text().trim().replace(/\n/g, ' ').replace(/  /g, '');
                var label = title.substring(0, 30) + " : " + $clicked.attr('href');

                dataLayer.push({
                    'event' : 'GAEvent',
                    'eventCategory' : 'Klik',
                    'eventAction' : 'Link',
                    'eventLabel' :label,
                    'eventValue' : undefined
                });
            }

        } else {

            dataLayer.push({
                'event' : 'GAEvent',
                'eventCategory' : 'Klik',
                'eventAction' : 'Link',
                'eventLabel' : unique,
                'eventValue' : undefined
            });
        }
    });

    function analyticsScrollEvents ( $floatingElems ) {

        // offset top makes error with offset()
        if ( !$floatingElems.length ) { return; }

        var windowHeight = $( window ).height(),
            elements = [];

        $floatingElems.each(function(){

            elements.push(  $(this) );

        });

        var elementsLength = elements.length;



        // scroll trotting
        var scrollEnabled = false;
        $( window ).scroll( checkTopPosition );

        setInterval(function() {

            if( scrollEnabled ) {
                scrollEnabled = false;
            }
        }, 300);



        // showing when share buttons disappeare
        function checkTopPosition() {

            scrollEnabled = true; // scroll trotting

            var windowTop = $(window).scrollTop();

            for( var i = 0; i < elementsLength; i++ ){

                var $floatingElem = elements[i],
                    offsetTop     = $floatingElem.offset().top;

                // console.log ('windowTop: ' + windowTop + ' > offsetTop: ' + offsetTop || windowTop + windowHeight < offsetTop);
                if (windowTop < offsetTop && ( windowTop + windowHeight ) > offsetTop) {

                    if( !$floatingElem.hasClass('floatingElem_registrated') ) {

                        $floatingElem.addClass('floatingElem_registrated');

                        dataLayer.push({
                            'event' : 'GAEvent',
                            'eventCategory' : 'PageScroll',
                            'eventAction' : $floatingElem.data('eventaction'),
                            'eventLabel' : $floatingElem.data('eventlabel'),
                            'eventValue' : undefined
                        });
                    }
                }
            }
        }
    }

    analyticsScrollEvents ( $('.js_analyticsScrollEvent') );
});