
/*jshint multistr: true */

import { Cowboy } from '../lib/jquery.ba-throttle-debounce.min.js';
require('../lib/magnific_popup_main.js')
require('../lib/nanoScrollerJS.js')
require('../lib/glDatePicker.js')
require('../lib/jquery.inputmask.bundle.js')
require('../lib/jquery.clickout.js')
require('../lib/iframeResizer.min.js')
require('../lib/stickyfill.js')
require('../lib/jquery.dotdotdot.min.js')
require('../lib/jquery-ui.js')
require('../lib/jquery-cookie-1-4-1.js')

require('./site_config.js')
require('./modules/analytics_events.js')
require('./modules/navigation.js')
require('./modules/user_profile.js')
require('./modules/sticky.js')
require('./modules/load_more.js')
require('./modules/form_submit.js')
require('./modules/weather.js')
require('./modules/ticker.js')
require('./modules/didomi.js')

$.throttle = Cowboy.throttle;
$.debounce = Cowboy.debounce;

$(document).ready(function () {
    /*
     * classList.js: Cross-browser full element.classList implementation.
     * 2014-07-23
     *
     * By Eli Grey, http://eligrey.com
     * Public Domain.
     * NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
     */

    /* global self, document, DOMException */

    /*! @source http://purl.eligrey.com/github/classList.js/blob/master/classList.js */

    if ("document" in self) {

        // Full polyfill for browsers with no classList support
        if (!("classList" in document.createElement("_"))) {

            (function (view) {

                "use strict";

                if (!('Element' in view)) return;

                var
                    classListProp = "classList",
                    protoProp = "prototype",
                    elemCtrProto = view.Element[protoProp],
                    objCtr = Object,
                    strTrim = String[protoProp].trim || function () {
                        return this.replace(/^\s+|\s+$/g, "");
                    },
                    arrIndexOf = Array[protoProp].indexOf || function (item) {
                        var
                            i = 0,
                            len = this.length;
                        for (; i < len; i++) {
                            if (i in this && this[i] === item) {
                                return i;
                            }
                        }
                        return -1;
                    },
                    // Vendors: please allow content code to instantiate DOMExceptions

                    DOMEx = function (type, message) {
                        this.name = type;
                        this.code = DOMException[type];
                        this.message = message;
                    },
                    checkTokenAndGetIndex = function (classList, token) {
                        if (token === "") {
                            throw new DOMEx(
                                "SYNTAX_ERR", "An invalid or illegal string was specified"
                            );
                        }
                        if (/\s/.test(token)) {
                            throw new DOMEx(
                                "INVALID_CHARACTER_ERR", "String contains an invalid character"
                            );
                        }
                        return arrIndexOf.call(classList, token);
                    },
                    ClassList = function (elem) {
                        var
                            trimmedClasses = strTrim.call(elem.getAttribute("class") || ""),
                            classes = trimmedClasses ? trimmedClasses.split(/\s+/) : [],
                            i = 0,
                            len = classes.length;
                        for (; i < len; i++) {
                            this.push(classes[i]);
                        }
                        this._updateClassName = function () {
                            elem.setAttribute("class", this.toString());
                        };
                    },
                    classListProto = ClassList[protoProp] = [],
                    classListGetter = function () {
                        return new ClassList(this);
                    };
                // Most DOMException implementations don't allow calling DOMException's toString()
                // on non-DOMExceptions. Error's toString() is sufficient here.
                DOMEx[protoProp] = Error[protoProp];
                classListProto.item = function (i) {
                    return this[i] || null;
                };
                classListProto.contains = function (token) {
                    token += "";
                    return checkTokenAndGetIndex(this, token) !== -1;
                };
                classListProto.add = function () {
                    var
                        tokens = arguments,
                        i = 0,
                        l = tokens.length,
                        token, updated = false;
                    do {
                        token = tokens[i] + "";
                        if (checkTokenAndGetIndex(this, token) === -1) {
                            this.push(token);
                            updated = true;
                        }
                    }
                    while (++i < l);

                    if (updated) {
                        this._updateClassName();
                    }
                };
                classListProto.remove = function () {
                    var
                        tokens = arguments,
                        i = 0,
                        l = tokens.length,
                        token, updated = false,
                        index;
                    do {
                        token = tokens[i] + "";
                        index = checkTokenAndGetIndex(this, token);
                        while (index !== -1) {
                            this.splice(index, 1);
                            updated = true;
                            index = checkTokenAndGetIndex(this, token);
                        }
                    }
                    while (++i < l);

                    if (updated) {
                        this._updateClassName();
                    }
                };
                classListProto.toggle = function (token, force) {
                    token += "";

                    var
                        result = this.contains(token),
                        method = result ?
                            force !== true && "remove" :
                            force !== false && "add";

                    if (method) {
                        this[method](token);
                    }

                    if (force === true || force === false) {
                        return force;
                    } else {
                        return !result;
                    }
                };
                classListProto.toString = function () {
                    return this.join(" ");
                };

                if (objCtr.defineProperty) {
                    var classListPropDesc = {
                        get: classListGetter,
                        enumerable: true,
                        configurable: true
                    };
                    try {
                        objCtr.defineProperty(elemCtrProto, classListProp, classListPropDesc);
                    } catch (ex) { // IE 8 doesn't support enumerable:true
                        if (ex.number === -0x7FF5EC54) {
                            classListPropDesc.enumerable = false;
                            objCtr.defineProperty(elemCtrProto, classListProp, classListPropDesc);
                        }
                    }
                } else if (objCtr[protoProp].__defineGetter__) {
                    elemCtrProto.__defineGetter__(classListProp, classListGetter);
                }

            }(self));

        } else {
            // There is full or partial native classList support, so just check if we need
            // to normalize the add/remove and toggle APIs.

            (function () {
                "use strict";

                var testElement = document.createElement("_");

                testElement.classList.add("c1", "c2");

                // Polyfill for IE 10/11 and Firefox <26, where classList.add and
                // classList.remove exist but support only one argument at a time.
                if (!testElement.classList.contains("c2")) {
                    var createMethod = function (method) {
                        var original = DOMTokenList.prototype[method];

                        DOMTokenList.prototype[method] = function (token) {
                            var i, len = arguments.length;

                            for (i = 0; i < len; i++) {
                                token = arguments[i];
                                original.call(this, token);
                            }
                        };
                    };
                    createMethod('add');
                    createMethod('remove');
                }

                testElement.classList.toggle("c3", false);

                // Polyfill for IE 10 and Firefox <24, where classList.toggle does not
                // support the second argument.
                if (testElement.classList.contains("c3")) {
                    var _toggle = DOMTokenList.prototype.toggle;

                    DOMTokenList.prototype.toggle = function (token, force) {
                        if (1 in arguments && !this.contains(token) === !force) {
                            return force;
                        } else {
                            return _toggle.call(this, token);
                        }
                    };
                }
                testElement = null;
            }());
        }
    }

    var classNames = [];
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios');
    if (navigator.userAgent.match(/android/i)) classNames.push('device-android');

    var html = document.getElementsByTagName('html')[0];

    if (classNames.length) classNames.push('on-device');
    if (html.classList) html.classList.add.apply(html.classList, classNames);

    /* AJAX SETUP */
    var csrftoken = $.cookie('csrftoken');

    function csrfSafeMethod(method) {
        // these HTTP methods do not require CSRF protection
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }

    function sameOrigin(url) {
        // test that a given url is a same-origin URL
        // url could be relative or scheme relative or absolute
        var host = document.location.host; // host + port
        var protocol = document.location.protocol;
        var sr_origin = '//' + host;
        var origin = protocol + sr_origin;
        // Allow absolute or scheme relative URLs to same origin
        return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
            (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') ||
            // or any other URL that isn't scheme relative or absolute i.e relative.
            !(/^(\/\/|http:|https:).*/.test(url));
    }

    $.ajaxSetup({

        beforeSend: function (xhr, settings) {
            if (!csrfSafeMethod(settings.type) && sameOrigin(settings.url)) {
                // Send the token to same-origin, relative URLs only.
                // Send the token only if the method warrants CSRF protection
                // Using the CSRFToken value acquired earlier
                xhr.setRequestHeader("X-CSRFToken", csrftoken);
            }
        }
    });
    /* END AJAX SETUP */

    function selectionInjection() {
        $(".article__content").on('copy', function (e) {

            var selectedText = window.getSelection();

            var copyAddedText = "<br /><br /> Več na: <a href='" + document.location.href + "'>" +
                document.location.href + "</a> - " + document.location.hostname;

            var selectionHelper = $('<div>', { style: { position: 'absolute', left: '-99999px' }, html: selectedText + copyAddedText });
            $('body').append(selectionHelper);

            selectedText.selectAllChildren(selectionHelper[0]);

            window.setTimeout(function () {
                selectionHelper.remove();
            }, 0);
        });
    }

    function initSticky() {

        if ($('.sticky').length > 0) {

            $('.sticky').Stickyfill();
            $('.sticky').addClass('js_initStickyFN');

        }

        setTimeout(function () {
            Stickyfill.rebuild();
        }, 1500);
    }

    function initNano() {

        $('.nano').nanoScroller({
            'preventPageScrolling': true,
            'iOSNativeScrolling': true,
        });
    }

    function sidebarFeed() {
        var windowWidth = window.outerWidth;

        if (windowWidth > 1024) {
            var $sidebarContent = $('.js_sidebarFeed');
            var $mainContent = $('.js_sidebarFeedHeightElement');

            setTimeout(function () {
                var elementHeight = $mainContent.height() - 52 - 25;
                if ($sidebarContent.length > 0 && $mainContent.length > 0) {
                    $sidebarContent.css({ 'height': elementHeight + 'px' });
                    $('.sidebar__feed--nano').nanoScroller({
                        'preventPageScrolling': true,
                        'iOSNativeScrolling': true,
                    });
                }
            }, 500);
        }
    }

    function avtoWidget() {
        $('.js-auto-widget').nanoScroller({
            'preventPageScrolling': true,
            'iOSNativeScrolling': true,
        });
    }

    function initOnLoad() {

        $('.js_initOnLoad').removeClass('hidden');

    }

    function expandToolDescription() {

        var $tool = $('.js_tool');

        if ($tool.length) {

            var $expandBtn = $tool.find('.js_showFullDescription'),
                $toolDescription = $tool.find('.js_toggleDescription');

            $expandBtn.on('click', function (event) {

                event.preventDefault();
                $toolDescription.toggleClass('is_hidden');

            });
        }
    }

    function initIframeResize() {
        $('.tes_widget > iframe').iFrameResize();
        $('.article__more > div > iframe').iFrameResize();
    }

    setTimeout(function () {
        initIframeResize();
    }, 800);

    function lineClamp() {

        $('.js_lineclamp ').dotdotdot({});

        setTimeout(function () {
            $('.js_lineclamp ').trigger("update");
        }, 300);
    }

    function cookieControll() {

        window.forbiddenAnalyticsCookie = getControllCookie('CC__analiticni_piskotki__') || 1;
        window.forbiddenAdsCookie = getControllCookie('CC__oglasevalski_piskotki__') || 1;
        window.forbiddenSocialCookie = getControllCookie('CC__druzbena_omrezja_piskotki__') || 1;

        $('#analiticni_piskotki_r' + forbiddenAnalyticsCookie).prop('checked', true);
        $('#oglasevalski_piskotki_r' + forbiddenAdsCookie).prop('checked', true);
        $('#druzbena_omrezja_piskotki_r' + forbiddenSocialCookie).prop('checked', true);

        $('#analiticni_piskotki_table').css('opacity', 0.6 + (forbiddenAnalyticsCookie * 0.4));
        $('#oglasevalski_piskotki_table').css('opacity', 0.6 + (forbiddenAdsCookie * 0.4));
        $('#druzbena_omrezja_piskotki_table').css('opacity', 0.6 + (forbiddenSocialCookie * 0.4));

        $('.radiobutton--group [name=analiticni_piskotki]').on('change', function () {
            setControllCookie('CC__analiticni_piskotki__', $(this).val(), 365);
            $('#analiticni_piskotki_table').css('opacity', 0.6 + ($(this).val() * 0.4));
        });

        $('.radiobutton--group [name=oglasevalski_piskotki]').on('change', function () {
            setControllCookie('CC__oglasevalski_piskotki__', $(this).val(), 365);
            $('#oglasevalski_piskotki_table').css('opacity', 0.6 + ($(this).val() * 0.4));
        });

        $('.radiobutton--group [name=druzbena_omrezja_piskotki]').on('change', function () {
            setControllCookie('CC__druzbena_omrezja_piskotki__', $(this).val(), 365);
            $('#druzbena_omrezja_piskotki_table').css('opacity', 0.6 + ($(this).val() * 0.4));
        });

    }

    function showStickyFooterBanner() {

        var $banner = $('.js_stickyFooterBanner');
        var bannerVisible = false;

        if (!$banner.length || bannerVisible) return;

        function showBanner() {

            var pageHeight = $('body').height();
            var showBannerPoint = pageHeight / 4;
            var windowScroll = $(window).scrollTop();

            if (windowScroll >= showBannerPoint) {

                $banner.addClass('is-visible');
                bannerVisible = true

            }

        }

        $(window).scroll($.throttle(500, showBanner));

    }

    function accordion() {
        $('#js_accordion').accordion({
            header: ".js_accoheader",
            heightStyle: 'content',
            active: true,
            collapsible: true,
            icons: {
                "header": "ui-icon-plus",
                "activeHeader": "ui-icon-minus"
            },
            classes: {
                "ui-accordion-header-collapsed": "ui-accordion__header-collapsed",
                "ui-accordion-header-active": "ui-accordion__header-active",
                "ui-accordion-content-active": "ui-accordion__content-active"
            }
        });
    }

    $.fn.contentIframeHandler = function (selector) {

        return $(this).each(function () {

            var container = $(this).closest('.article__aditionl_content'),
                wrapper = null,
                iframe = $(this),
                resizeTimeout = null,
                time = 200,
                ww = null,
                wbreak = 1024,
                wwide = 1370,
                type = null,
                dataHeight = {
                    mob: null,
                    desk: null,
                    wide: null
                };

            var init = function () {
                if (iframe.length) {
                    ww = window.innerWidth;
                    setHeights();
                    initSelector();
                    if (type === 'rwd') $(window).resize(onResize);
                }
                else return;
            };

            var setHeights = function () {
                var arr = [];
                if (iframe.attr('data-height')) arr = iframe.attr('data-height').split('x');
                if (arr.length > 1) {
                    dataHeight.mob = arr[0];
                    dataHeight.desk = arr[1];
                    dataHeight.wide = arr[2];
                    iframe.removeAttr('height');
                    type = 'rwd';
                } else if (arr.length === 1) {
                    dataHeight.desk = arr[0];
                    iframe.removeAttr('height');
                    type = 'fixed';
                }
                else type = 'fixed';
            };

            var initSelector = function () {
                if (type === 'rwd') {
                    if (dataHeight.wide && ww > wwide) initWide();
                    if (dataHeight.desk && ww > wbreak && ww < wwide) initDesk();
                    if (dataHeight.mob && ww < wbreak) initMobile();
                } else {
                    if (iframe.attr('height') || dataHeight.desk) initUniversal();
                    else return;
                }
            };

            var initUniversal = function () {
                var hData = parseInt(iframe.attr('height'), 10) || parseInt(dataHeight.desk, 10);
                addWrapper();
                calcHeight(hData);
            };

            var initWide = function () {
                var hData = parseInt(dataHeight.wide, 10);
                addWrapper();
                calcHeight(hData);
            };

            var initDesk = function () {
                var hData = parseInt(dataHeight.desk, 10);
                addWrapper();
                calcHeight(hData);
            };

            var initMobile = function () {
                var hData = parseInt(dataHeight.mob, 10);
                addWrapper();
                calcHeight(hData);
            };

            var addWrapper = function () {
                if (wrapper === null) {
                    iframe.wrap('<div class="js_iframeWrapper"></div>');
                    wrapper = iframe.closest('.js_iframeWrapper');
                }
            };

            var calcHeight = function (hData) {
                var w = container.width(),
                    h = parseInt(hData, 10),
                    calcHeight = Math.ceil(h / w * 100) + 2;
                wrapper.css({ 'paddingBottom': calcHeight + '%' });
                iframe.removeAttr('height');
            };

            var onResize = function () {
                if (resizeTimeout) {
                    clearTimeout(resizeTimeout);
                }
                resizeTimeout = setTimeout(function () {
                    // do stuff on resize
                    ww = window.innerWidth;
                    initSelector();
                }, time);
            };
            init();
        });
    };

    initOnLoad();
    selectionInjection();
    initSticky();
    initNano();
    expandToolDescription();
    initIframeResize();
    cookieControll();
    lineClamp();
    showStickyFooterBanner();
    accordion();
    $('.article__aditionl_content iframe').contentIframeHandler();
    sidebarFeed();
    avtoWidget();

    // use debounce so function is not called multiple times on windows resize event
    $(window).resize($.debounce(300, sidebarFeed));

});
