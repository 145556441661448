/*jshint multistr: true */

$(function() {

    function loadModalTemplate ( $form, e, options ) {

        e.preventDefault();

        var magnificPopup = $.magnificPopup.instance;

        var form_data = $form.serialize();

        if(form_data.indexOf('csrfmiddlewaretoken') == -1 ) {
            form_data += '&csrfmiddlewaretoken=' + $('.js_tokie').data('tokie');
        }

        $.ajax({
            type: "POST",
            url: $form.data('url'),
            data: form_data,

            beforeSend: function() {

                $('.mfp-content .modal__left').html('<img src="/static/img/loader.svg">');
            },

            success: function(data, textStatus, xhr) {

                if (xhr.status == 200){

                    if ( data.status == 'success' ){

                        magnificPopup.close();
                        location.reload();

                    } else {

                        if (options != 'not_modal') {

                            $('.mfp-content').html($(data).find('.js_userInteraction'));
                            initUserInteractions();
                        } else {
                            $form.remove();
                            $('.newsletter__txt').text($(data).find('p').text());
                        }
                    }
                }
            },

            error: function(data, status, xhr) {

                $('.mfp-content').html(data.responseText);
                setModalClose();

            }
        });
    }

    function loadModalTemplateFiles ( $form, e, options ) {

        e.preventDefault();

        var magnificPopup = $.magnificPopup.instance;

        var formdata = false;
        if (window.FormData){
            formdata = new FormData($form[0]);
        }

        $.ajax({
            type: "POST",
            url: $form.data('url'),
            data: formdata ? formdata : $form.serialize(),
            cache:false,
            contentType: false,
            processData: false,

            beforeSend: function() {

                $('.mfp-content .modal__left').html('<img src="/static/img/loader.svg">');
            },

            success: function(data, textStatus, xhr) {

                if (xhr.status == 200){

                    initUserInteractions();

                    $('.modal__lead').text($(data).find('p').text());
                    $('.js_UGCstep2').slideUp();

                    setTimeout(function(){

                        magnificPopup.close();

                    }, 3000);
                }
            },

            error: function(data, status, xhr) {

                $('.mfp-content').html(data.responseText);
                setModalClose();

            }
        });
    }

    function initUserLogin() {

        $('.js_userLogin').addClass('js_initUserLoginFN');

        $('.js_userLogin').on('submit', function( e ) {

            var $form = $(this);
            loadModalTemplate ( $form, e );
        });
    }

    function initUserRegister() {

        $('.js_userRegistration').addClass('js_initUserRegisterFN');

        $('.js_userRegistration').on('submit', function( e ) {

            var $form = $(this);
            loadModalTemplate ( $form, e );
        });
    }

    function initUserResetPass() {

        $('.js_resetPassword').addClass('js_initUserResetPasswordFN');

        $('.js_resetPassword').on('submit', function( e ) {

            var $form = $(this);
            loadModalTemplate ( $form, e );
        });
    }

    function initUserActivation() {

        $('.js_userActivation').addClass('js_initUserActivationFN');

        $('.js_userActivation').on('submit', function( e ) {

            var $form = $(this);
            loadModalTemplate ( $form, e );
        });
    }

    function initUGC() {

        $('.js_UGC_send').addClass('js_UGC_sendFN');

        var $goToStep2  = $('.js_goUGCs2');
        var $fakeUpload = $('.js_fakeUpload');
        var $realUpload = $('.js_realUpload');

        $goToStep2.on('click', function () {

            var validator = $('.js_UGC_send').validate({
                messages: {

                    name:  'Obavezno polje.',
                    email: 'Unesite ispravnu Email adresu',
                    phone: 'Obavezno polje.',
                }
            });

            var vName   = validator.element('.js_name');
            var vEmail  = validator.element('.js_email');
            var vPhone  = validator.element('.js_phone');

            if( vName && vEmail && vPhone ) {
                $('.js_UGCstep1').hide();
                $('.js_UGCstep2').show();
                $('.js_modalStepGraphic').addClass('modal__step_graphics--2');

                $('.js_fakeUpload1').on('click', function (e) {
                    e.preventDefault();

                    $('.js_realUpload1').trigger('click');
                });

                $('.js_fakeUpload2').on('click', function (e) {
                    e.preventDefault();

                    $('.js_realUpload2').trigger('click');
                });

                $('.js_fakeUpload3').on('click', function (e) {
                    e.preventDefault();

                    $('.js_realUpload3').trigger('click');
                });
            }
        });

        $('.js_UGC_send').on('submit', function( e ) {

            var $form = $(this);
            loadModalTemplateFiles ( $form, e );
        });
    }

    function setModalClose() {

        $('.js_modalClose').addClass('js_modalCloseFN');

        $('.js_modalClose').on('click', function(evt) {

            if ( $('body').hasClass('standalone_modal') || $('body').hasClass('modal--standalone') ) {
                return;
            } else {
                evt.preventDefault();
            }

            var magnificPopup = $.magnificPopup.instance;
            magnificPopup.close();

        });
    }

    function setModalLinks() {

        $('.js_loginPlease, .js_userRegister, .js_resetPass, .js_changeAvatar').addClass('init').magnificPopup({

            type: 'ajax',
            ajax: {
                settings: {
                    // headers: { "X-Request-Content": "Snippet" }
                }
            },

            callbacks: {

                parseAjax: function(mfpResponse) {
                    // mfpResponse.data is a "data" object from ajax "success" callback
                    // for simple HTML file, it will be just String
                    // You may modify it to change contents of the popup
                    // For example, to show just #some-element:
                    // mfpResponse.data = $(mfpResponse.data).find('#some-element');

                    // mfpResponse.data must be a String or a DOM (jQuery) element

                    $('.mfp-content').html('');
                    mfpResponse.data = $(mfpResponse.data).find('.js_userInteraction');
                },

                ajaxContentAdded: function() {

                    initUserInteractions();
                }
            },

            modal: true

        });

        $('.js_UGC').addClass('init').magnificPopup({

            type: 'ajax',
            ajax: {
                settings: {
                    // headers: { "X-Request-Content": "Snippet" }
                }
            },

            callbacks: {

                ajaxContentAdded: function() {

                    initUserInteractions();
                }
            },

            modal: true

        });
    }

    function initUserInteractions() {

        setModalClose();
        setModalLinks();

        initUGC();
        initActual();

        initUserLogin();
        initUserRegister();
        initUserResetPass();
        initUserActivation();
    }

    function initActual() {

        $('.js_newsletter').addClass('js_newsletterFN');

        $('.js_newsletter').on('submit', function( e ) {

            var $form = $(this);
            loadModalTemplate ( $form, e, 'not_modal' );
        });
    }

    // inicijalizacija user interakcija

    initUserInteractions();

});
