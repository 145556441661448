/*jshint multistr: true */

$(function () {



    function initSVG() {

        /*
         * Replace all SVG images with inline SVG
         */


        $('img.svg').each(function(){

            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });

    }


    function changeCity () {

        $('.js_weatherCitySelect').on('change', function(){

            var cityLink= $(this).val();

            if (cityLink) {
                window.location = cityLink;
            }
        });
    }


    function weatherWidgetSelect () {

        if( $('.js_weatherWidgetSelect').hasClass('js_weatherWidgetSelect_init') ) { return; }
        $('.js_weatherWidgetSelect').addClass('js_weatherWidgetSelect_init');

        var weatherWidget = $('.js_weatherWidget');

        $('.js_weatherWidget').on('change', '.js_weatherWidgetSelect', function(){
            weatherWidget.load( $(this).val() + ' .js_weatherWidgetInner', function() {
                initSVG ();
            });
        });
    }


    function weatherWidgetAjax () {

        var weatherWidget = $('.js_weatherWidget');

        if( weatherWidget.length && weatherWidget.data('weather-api') ) {

            weatherWidget.load( weatherWidget.data('weather-api') + ' .js_weatherWidgetInner', function() {
                initSVG ();
            });

        }

    }


    weatherWidgetAjax();
    weatherWidgetSelect();
    initSVG ();
    changeCity ();

});