$(function () {

    function newsletterSubmit() {

        var $newsletter = $('.newsletter');

        if( $newsletter.length ) {

            var $btn        = $newsletter.find('.js_newsletterSubmit'),
                $successMsg = $newsletter.find('.js_successMsg'),
                $form       = $newsletter.find('.newsletter__form');

            $btn.on('click', function (e) {

                e.preventDefault();

                var csrftoken = $.cookie('csrftoken');

                $.ajax({
                    type: "POST",
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    dataType: 'json',

                    beforeSend: function(xhr, settings) {
                        xhr.setRequestHeader("X-CSRFToken", csrftoken);
                    },

                    success: function (data) {

                        //TODO: u modalu prikazati uspješnu poruku

                        //console.log(data.success);


                        var $errorElem = $form.find('.has_error');

                        //brisanje forme
                        $form.addClass('is_hidden');
                        $successMsg.removeClass('is_hidden');

                    },

                    error: function (data) {

                        //console.log(data);

                        for( var key in data.responseJSON ) {

                            var field           = key,
                                value           = data.responseJSON[key][0],
                                $inputElement   = $form.find('[name="'+ key +'"]'),
                                $errorElem      = $inputElement.siblings('.form__hint_wrap').find('.form__input_error');

                            $inputElement.parents('.form__row').addClass('has_error');
                            $errorElem.empty().append(value);

                        }
                    }
                });
            });

        }
    }

    newsletterSubmit();

});