/*jshint multistr: true */

$(function() {

    if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function(callback, element) {
            var currTime = new Date().getTime(),
                timeToCall = Math.max(0, 16 - (currTime - lastTime)),
                id = window.setTimeout(function() { callback(currTime + timeToCall); }, timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };
    }

    var supports = (function() {
        var div = document.createElement('div'),
            vendors = 'Khtml Ms O Moz Webkit'.split(' '),
            len = vendors.length;

        return function(prop) {
            if (prop in div.style) return true;

            prop = prop.replace(/^[a-z]/, function(val) {
                return val.toUpperCase();
            });

            while (len--) {
                if (vendors[len] + prop in div.style) {
                    // browser supports box-shadow. Do what you need.
                    // Or use a bang (!) to test if the browser doesn't.
                    return true;
                }
            }
            return false;
        };
    })();


    var transformSupport = false;

    if (supports('transform')) {
        transformSupport = true;
    }

    var $ticker = $('.js_ticker');
    var $listContainer = $ticker.find('.ticker__list_container');
    var $newsList = $ticker.find('.ticker__list');
    // var $rightArrow     = $('.ticker__arrow--right');
    // var $leftArrow      = $('.ticker__arrow--left');

    var newsWidth = 0; // sirina liste
    var counter = 0; // counter se koristi kada se klikna desna strelica
    var goTo; // tocka inicijalnog pomaka
    var speed; // tocka inicijalne brzine
    var pause = false; // za pauziranje tickera

    // napravi se kopija prije i poslije originalne liste
    $newsList.clone().appendTo($listContainer);
    $newsList.clone().prependTo($listContainer);

    newsWidth = $listContainer.width();


    goTo = Math.ceil(newsWidth); // izračunati pomak
    direction = 'normal'; // defaultna brzina


    //sirina containera je 3 puta sirina liste + 10px
    //translate je postavljen na goTo koji je inicijalno - newsWidth
    $listContainer.css({
        'transform': 'translateX(' + goTo + 'px)',
        'width': (3 * newsWidth) + 10 + 'px'
    });



    function runTicker() {

        // mjenjamo zbrojeve ocisno o smjeru
        switch (direction) {

            case 'normal':

                speed = -1; // nornalna brzina
                break;

            case 'hover':   // pauza na hover

                speed = 0;
                break;

            default:

                speed = -1;

        }


        // matematika za ticker
        if( Math.abs(goTo) < (newsWidth * 2)) {

            goTo += speed;

        } else {

            goTo = Math.abs(speed);

        }



        // nakon izračuna pozicioniramo ticker
        if (transformSupport === true) {

            $listContainer.css({
                'transform': 'translateX(' + Math.floor(goTo) + 'px)'
            });

        } else {

            $listContainer.css({
                'margin-left': Math.floor(goTo) + 'px'
            });
        }
    }

    // pauziranje tickera
    $listContainer.on('mouseover', function() {
        direction = 'hover';
    });

    $listContainer.on('mouseout', function() {
        direction = 'normal';
    });





    // svakih 25 milisekundi pokreni funkciju
    setInterval(function() {

        requestAnimationFrame(runTicker);

    }, 16);
});
