$(function () {

    function initDidomi() {
        var $btn = $('.js_didomiPreferences'),
            $didomiSticky = $('.js_stickyDidomi');


        setTimeout(function(){
            if (typeof Didomi == 'object') {
                $didomiSticky.removeClass('hidden');
                $btn.on('click', function(){
                    Didomi.preferences.show();
                });
            }
        }, 3000);

    }

    initDidomi();

});